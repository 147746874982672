'use client';
import {FC, useEffect, useState} from 'react';
import styles from './SearchConsole.module.scss';
import Image from 'next/image';
import Link from 'next-intl/link';
import Logo from '../../../src/app/assets/icons/logo.svg';
import SearchComponent from '../SearchComponent';
import Globus from '../../../src/app/assets/icons/globus.svg';
import Person from '../../../src/app/assets/icons/person.svg';
import Logout from '../../../src/app/assets/icons/logout.svg';
import {useTranslations} from 'next-intl';
import {useRouter} from 'next-intl/client';
import {usePathname} from 'next/navigation';
import AuthPopup from '../AuthPopup';
import {useCookies} from 'react-cookie';
import {useLocalStorage} from "usehooks-ts";
import ButtonElement from "@/shared/ButtonElement";
import WaitlistPopupContainer from "@/shared/WaitlistPopupContainer";

interface SearchConsoleProps {
  className?: string;
}

const SearchConsole: FC<SearchConsoleProps> = ({className = ''}) => {
  const [search, setSearch] = useState<string>('');
  const [isOpenAuthForm, setIsOpenAuthForm] = useState<boolean>(false);
  const router = useRouter();
  const pathname = usePathname();
  const handleSearchEvent = (value: string) => {
    setSearch(value);
  };
  const t = useTranslations('SEARCH_CONSOLE');

  const [token, setToken] = useState<string>('')
  const [waitlistPopupOpen, setWaitlistPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    setToken(localStorage.getItem('token') || '')
  }, []);

  const handleChangeLanguage = () => {
    if (pathname.includes('/en')) {
      const newPath = pathname.replace('/en', '/ua');
      router.push(newPath, {locale: 'ua'});
    } else {
      router.push(pathname, {locale: 'en'});
    }
  };

  const handleAuthorize = () => {
    if (!token) {
      setIsOpenAuthForm(true);
    } else {
      localStorage.clear()
    }
  };

  return (
    <>
      <header className={`${styles.root} ${className} container`}>
        <div className={styles.container__left}>
          <Link prefetch={false} href="/">
            <Image src={Logo} alt="logo"/>
          </Link>
          <SearchComponent/>
        </div>

        <div className={styles.container__right}>
          <button
            className={`${styles.header__button} ${styles.header__button__text}`}
            aria-label={t('sell')}
            onClick={() => setWaitlistPopupOpen(true)}
          >
            <p>{t('sell')}</p>
          </button>
          <button
              onClick={handleChangeLanguage}
              className={`${styles.header__button} ${styles.header__button__icon}`}
              aria-label="change language"
              type="button">
            <Image src={Globus} alt="globus"/>
          </button>

          {token && (
              <>
                {/*<button className={`${styles['header__button--profile']}`} type="button">*/}
                {/*  <span>*/}
                {/*    <Image src={Person} alt="person" />*/}
                {/*  </span>*/}
                {/*  <p> {t('profile')}</p>*/}
                {/*</button>*/}
                <button
                    onClick={handleAuthorize}
                    className={`${styles.header__button} ${styles.header__button__icon}`}
                    aria-label="logout"
                    type="button">
                  <Image src={Logout} alt="globus"/>
                </button>
              </>
          )}
          {!token && (
              <button
                  onClick={handleAuthorize}
                  className={styles.header__button}
                  aria-label="log in"
                  type="button">
                <Image src={Person} alt="person"/>
              </button>
          )}
        </div>
      </header>
      <WaitlistPopupContainer
        isOpen={waitlistPopupOpen}
        setIsOpen={setWaitlistPopupOpen}
      />
      <AuthPopup setIsOpen={() => setIsOpenAuthForm(false)} woRedirect isOpen={isOpenAuthForm}/>
    </>
  );
};

export default SearchConsole;
